import { logger } from '@/lib/logger';
import { api, slotsApi, withoutToken } from '@/lib/axios';

import {
  DailyRewardType,
  FriendType,
  LeaderboardPlayer,
  OfferingType,
  OfferingTypeType,
  QuestType,
  RewardType,
  UserType,
} from '@/types';
import { LocalStorageService } from '@/services/localStorage.service';
import { useCurrentLocale } from '@/locales/client';

export const createJwt = async ({
  initData,
  referral_code,
}: {
  initData: string;
  referral_code?: string | null;
}): Promise<{
  access: string;
  refresh: string;
}> => {
  return await withoutToken
    .post('/auth/jwt/create', {
      initData,
      referral_code: referral_code ?? null,
    })
    .then((res) => res.data);
};

export const verifyJwt = async ({ token }: { token: string }) => {
  console.log({ token });
  try {
    return await withoutToken
      .post('/auth/jwt/verify/', {
        token,
      })
      .then((res) => res.data);
  } catch (err) {
    console.log(err);
  }
};

export const refreshJwt = async ({ refresh }: { refresh: string }) => {
  try {
    return await withoutToken
      .post('/auth/jwt/refresh/', {
        refresh,
      })
      .then((res) => res.data);
  } catch (err) {
    console.log(err);
  }
};

export const getMe = async (): Promise<UserType | null> => {
  try {
    const user = await api.get('/auth/users/me').then((res) => res.data);

    logger.debug(user);

    return user as UserType;
  } catch (error) {
    logger.error(error);

    return null;
  }
};

export const getMeClient = (): Promise<UserType> | null => {
  try {
    return api.get('/auth/users/me').then((res) => res.data);
  } catch (error) {
    // @todo use TokenService.removeTokens()
    LocalStorageService.remove('access_token');
    LocalStorageService.remove('refresh_token');
    return null;
  }
};

export const sendTaps = async ({
  current_energy,
  earned,
}: {
  current_energy: number;
  earned: number;
}) => {
  return await api
    .post('/mining/taps', { current_energy, earned })
    .then((res) => res.data);
};

export const getFriends = async (): Promise<FriendType[]> => {
  const friends = await api.get('/auth/users/friends').then((res) => res.data);

  return friends;
};

export const getDailyRewards = async (): Promise<DailyRewardType[]> => {
  const daily_rewards = await api
    .get('/mining/daily-rewards')
    .then((res) => res.data.payload);

  return daily_rewards;
};

interface UserModalPopup {
  description: string;
  image: string;
  show: boolean;
  title: string;
}

export const getUserModal = async (): Promise<UserModalPopup> => {
  const locale = useCurrentLocale();
  const userModal = await api
    .get('/auth/users/modals', { headers: { 'Accept-Language': locale } })
    .then((res) => res.data);

  return userModal;
};

export const getQuests = async (language: string): Promise<QuestType[]> => {
  const quests = await api
    .get('/mining/quests', {
      headers: {
        'Accept-Language': language,
      },
    })
    .then((res) => res.data);

  return quests;
};

export const getArchiveQuests = async (
  language: string
): Promise<QuestType[]> => {
  const quests = await api
    .get('/mining/quests/archive', {
      headers: {
        'Accept-Language': language,
      },
    })
    .then((res) => res.data);

  return quests;
};

export const completeQuest = async (type: string) => {
  return await api.post('/mining/quests', { type }).then((res) => res.data);
};

export const getOfferings = async (
  type: OfferingTypeType
): Promise<OfferingType[]> => {
  const offerings = await api
    .get(`/mining/offers/${type}`)
    .then((res) => res.data);

  console.log(offerings);

  return offerings;
};

export const buyOffering = async (offer_id: number) => {
  return await api.post('/mining/purchase', { offer_id });
};

export const getLeaderboard = async (
  level: number
): Promise<LeaderboardPlayer[]> => {
  const leaderboard = await api
    .get('/auth/users/leaderboard', {
      params: { level },
    })
    .then((res) => res.data);

  return leaderboard;
};

export const resetEnergy = async () => {
  return await api.post('/mining/boosts/energy_reset').then((res) => res.data);
};

export const getIsMember = async (channel_id: string) => {
  return await api
    .get('/mining/telegram/ismember', {
      params: { channel_id },
    })
    .then((res) => res.data);
};

export const getIsBooster = async (channel_id: string) => {
  return await api
    .get('/mining/telegram/isboost', { params: { channel_id } })
    .then((res) => res.data);
};

export const spinWheel = async (): Promise<RewardType> => {
  return await api
    .post('/mining/wheel-of-fortune')
    .then((res) => res.data.reward);
};

export const upgradeEnergy = async () => {
  return await api.post('/mining/boosts/energy_lvl').then((res) => res.data);
};

export const upgradeMultitap = async () => {
  return await api.post('/mining/boosts/multitap_lvl').then((res) => res.data);
};

export const SlotsService = {
  getBalance: async () => {
    return slotsApi.get('/balance').then((res) => res.data);
  },
  deposit: async (amount: number) => {
    return fetch(`${import.meta.env.VITE_PUBLIC_SLOTS_API}/balance/deposit`, {
      method: 'POST',
      body: JSON.stringify({ amount: amount }),
      headers: {
        Authorization: `Bearer ${LocalStorageService.get('access_token')}`,
      },
    });
  },
  withdraw: async (amount: number) => {
    return fetch(`${import.meta.env.VITE_PUBLIC_SLOTS_API}/balance/withdraw`, {
      method: 'POST',
      body: JSON.stringify({ amount: amount }),
      headers: {
        Authorization: `Bearer ${LocalStorageService.get('access_token')}`,
      },
    });
  },
  getTransactionsHistory: async (limit = 10, offset = 0) => {
    return slotsApi
      .get('/balance/operations', {
        params: {
          limit,
          offset,
        },
      })
      .then((res) => res.data);
  },
  getLeaderboard: async (type: '24h' | '7d' = '24h') => {
    return slotsApi
      .get('/leaderboard', {
        params: {
          type,
        },
      })
      .then((res) => res.data);
  },
  getUserStats: async () => {
    return slotsApi.get('/user/stats').then((res) => res.data);
  },
  getUserGameHistory: async (limit = 10, offset = 0) => {
    return slotsApi
      .get('/user/session-stats', {
        params: {
          limit,
          offset,
        },
      })
      .then((res) => res.data);
  },
};

export interface PaymentCoinsType {
  level: number;
  amount: number;
  coin: number;
  discount: number;
}

export const getPaymentCoins = (): Promise<PaymentCoinsType[]> => {
  return api.get('/payment/coins/').then((res) => res.data);
};

export interface PaymentCharactersType {
  name: string;
  price: number;
  active_discount: boolean;
  discount: number;
}

export const getPaymentCharacters = (): Promise<PaymentCharactersType[]> => {
  return api.get('/payment/characters').then((res) => res.data);
};

interface PaymentStarsBody {
  type: 'quest' | 'level';
  order: number;
  title: string;
  description: string;
}

interface PaymentStarsRes {
  invoiceUrl: string;
}

export const paymentStars = (
  data: PaymentStarsBody
): Promise<PaymentStarsRes> => {
  return api.post('/payment/stars', data).then((res) => res.data);
};
