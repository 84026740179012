export default {
  nav: {
    exchange: 'Обмен',
    mine: 'Майнинг',
    friends: 'Друзья',
    earn: 'Заработок',
    airdrop: 'Эйрдроп',
  },
  exchange: {
    tap: 'Монет за тап',
    hour: 'Час',
    dailyBoosters: 'Ежедневные бустеры',
    fullEnergy: 'Полная энергия',
    available: 'доступно',
    go: 'Использ.',
    upgrade: 'Улуч.',
    turbo: 'Турбо',
    comingSoon: 'Скоро',
    boosters: 'Бустеры',
    multitap: 'Многократный тап',
    energyLimit: 'Лимит энергии',
    lvl: 'уровень',
    coinsEarned: 'Заработано монет',
    claimCoins: 'Забрать',
    lackOf: 'Недостаточно энергии',
    error: 'Ошибка',
    refilled: 'Энергия пополнена',
    success: 'Успешно',
    gameRoom: 'Игровая комната',
    passiveIncome: 'Пассивный доход активен только 4 часа пока вы оффлайн!',
  },
  league: {
    from: 'от',
    top: 'Топ-10 игроков в этой лиге',
  },
  mine: {
    spinTo: 'Крутите, чтобы выиграть монеты',
    spin: 'Крутить',
    strategy: 'Стратегия',
    markets: 'Рынки',
    marketing: 'Маркeт.',
    specials: 'Специал.',
    perHour: 'в час',
    buy: 'Купить',
    casinoBanner: 'Test your<br/>fortune<br/>in Casino!',
    bannerTest: 'Испытайте',
    bannerFortune: 'удачу',
    bannerCasino: 'в Казино!',
    playNow: 'Играть!',
  },
  friends: {
    inviteFriendsAnd: 'Приглашайте друзей и получайте бонусы вместе',
    inviteFriends: 'Пригласить друзей',
    directRef: 'Прямая рекомендация',
    refRef: 'Рекомендация от реферала',
    eachNext: 'Каждая последующая рекомендация',
    stars: 'Зарабатывайте Telegram Stars',
    starsRef: 'Получайте 30% от Telegram Stars потраченных рефералами',
  },
  earn: {
    daily: 'Ежедневные задания',
    day: 'День',
    claim: 'Забрать',
    weekly: 'Еженедельные задания',
    join: 'Подписаться',
    archive: 'Архив',
  },
  airdrop: {
    getReady: 'Готовьтесь, Эйрдроп скоро!',
    withExchanges: 'Переговоры с биржами',
    withMarketMakers: 'Переговоры с маркетмейкерами',
    key: 'Ключевые партнерства в пути',
    taskList: 'Список задач Эйрдропа',
  },
  wheel: {
    spinTo: 'Крутите чтобы получить награду',
    spin: 'Крутить',
    bonus: 'Бонус',
  },
  slots: {
    return: 'Назад',
    lobby: 'Лобби',
    top: 'Топ',
    profile: 'Профиль',
    wallet: 'Кошелек',
    onboarding1: `Это ваш баланс в казино,  
вы можете перевести монеты  
с игрового баланса в казино для игры.`,
    onboarding2: `Эта кнопка поможет вам пополнить  
баланс казино с помощью игровых монет.`,
    onboarding3: `Нажмите кнопку "Топ", чтобы перейти  
к таблице лидеров.
`,
    onboarding4: `Вы можете увидеть топ  
за последние 24 часа.
`,
    onboarding5: `Вы можете увидеть топ  
за последнюю неделю.
`,
    onboarding6: `Нажмите кнопку "Профиль", чтобы  
перейти в свой профиль.
`,
    onboarding7: `Это ваша общая статистика  
в нашем казино.
`,
    onboarding8: `Это ваша статистика игр:  
победы и поражения.
`,
    onboarding9: `Нажмите кнопку "Кошелек", чтобы  
перейти в ваш кошелек казино.
`,
    onboarding10: `Это ваш баланс в казино,  
вы можете перевести монеты  
с игрового баланса в казино для игры.
`,
    onboarding11: `Вы можете пополнить баланс казино  
или вывести его.
`,
    onboarding12: `Это история ваших транзакций —  
пополнений и выводов`,
    onboarding13: `Нажмите кнопку "Вернуться", чтобы  
вернуться к нашей игре.
`,
    onboarding14: `Удачи, теперь вы можете играть.`,
    onboarding15: 'Спасибо, если вы не пропустите обучение.',
    next: 'Далее',
    skip: 'Пропустить',
    topUp: 'Пополнить',
    gamesHistory: 'История игр',
    totalSpins: 'Кол-во спинов',
    totalWon: 'Кол-во побед',
    yourProfile: 'Ваш профиль',
    noData: 'Нет данных',
    day: '24 часа',
    week: 'Неделя',
    casinoWallet: 'Казино кошелек',
    balance: 'Баланс',
    withdraw: 'Вывод',
    transactionHistory: 'История транзакций',
    inGameBalance: 'Ваш внутриигровой баланс',
    inGame: 'Внутриигровой баланс',
    casinoBalance: 'Казино баланс',
    receive: 'Вы получите',
    give: 'Вы отдадите',
    yourCasinoBalance: 'Ваш казино баланс',
    toppedUp: 'Баланс пополнен!',
    withdrawed: 'Успешное снятие!',
    withdrawError: 'Ошибка при снятии',
    depositError: 'Ошибка при депозите',
  },
  stars: {
    buy: 'Купить Ocean Coins',
    select: 'Выберите количество монет. Чем больше монет, тем дешевле!',
    buyShort: 'Купить',
    support: 'Поддержите через Telegram Stars и получите Ocean Coins!',
    from: 'от',
  },
} as const;
